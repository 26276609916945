import { LastReadTimeContext } from "../definitions/enums";
import { ILastReadTime } from "../definitions/interfaces";
import BaseModel from "./base";

class LastReadTime extends BaseModel {
  /**
     * Get an issue from the API for editing
     *
     * @param {number} id
     */
   getAll(staff_user_id: number | string) {
    return this.http().get(`${this.baseUrl}/all-last-read-times/${staff_user_id}`).then(this.resolve).catch(this.reject);
   }

   get(staff_user_id: number | string , context: LastReadTimeContext){
     return this.http().get(`${this.baseUrl}/last-read-times/${staff_user_id}/${context}`).then(this.resolve).catch(this.reject);
   }

   create(lastReadTime: ILastReadTime){
    return this.http().post(`${this.baseUrl}/last-read-times`, lastReadTime).then(this.resolve).catch(this.reject);
   }

   update(staff_user_id: number, context: LastReadTimeContext, lastReadTime: ILastReadTime){
    return this.http().put(`${this.baseUrl}/last-read-times/${staff_user_id}/${context}`, lastReadTime).then(this.resolve).catch(this.reject);
   }
}

export default new LastReadTime();