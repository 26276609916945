import BaseModel from "./base";
import {UserRole} from '../definitions/enums';
import {INewCustomer, INewStaffMember, INewCustomerDto, IStaffMember, IUserPreferences} from '../definitions/interfaces';


class User extends BaseModel {
  /**
     * Get an issue from the API for editing
     *
     * @param {string} confirm_password
     * @param {string} password
     * @param {string} email
     * @param {string} username
     * @param {string} fullname
\      @returns @member any
     */

  createCustomer(customer: INewCustomer) {
    return this.http().post(`${this.baseUrl}/customer`, customer).then(this.resolve).catch(this.reject)
  }

  get(id: number | string) {
    return this.http().get(`${this.baseUrl}/users/${id}`).then(this.resolve).catch(this.reject)
  }

  createStaffMember(staffMember: IStaffMember) {
    return this.http().post(`${this.baseUrl}/users/staff-member`, staffMember).then(this.resolve).catch(this.reject)
  }

  update(id: number, staffMember) {
    return this.http().put(`${this.baseUrl}/users/staff-member/${id}`, staffMember).then(this.resolve).catch(this.reject)
  }

  updatePassword(id: number, user) {
    return this.http().put(`${this.baseUrl}/auth/update-password/${id}`, user).then(this.resolve).catch(this.reject)
  }

  createCustomerInvite(firstname: string,lastname: string,email: string,position: string) {
    return this.http().post(`${this.baseUrl}/create-staff-invite`, {
        firstname,
        lastname,
        email,
        position,
      })
      .then(this.resolve).catch(this.reject)
  }

  getAll() {
    return this.http().get(`${this.baseUrl}/users`).then(this.resolve).catch(this.reject)
  }

  uploadAvatar(id: number, form) {
    return this.http().post(`${this.baseUrl}/users/avatar/${id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          processData: "false",
        },
      }).then(this.resolve).catch(this.reject)
  }

  purgeAvatar(id: number) {
    return this.http().post(`${this.baseUrl}/users/purge-avatar/${id}`).then(this.resolve).catch(this.reject)
  }

  getUserPreferences() {
    return this.http().get(`${this.baseUrl}/users/user-preferences`).then(this.resolve).catch(this.reject)
  }

  createUserPreferences(preferences: Partial<IUserPreferences>) {
    return this.http().post(`${this.baseUrl}/users/user-preferences`, preferences).then(this.resolve).catch(this.reject)
  }

  updateUserPreferences(preferences: Partial<IUserPreferences>) {
    return this.http().put(`${this.baseUrl}/users/user-preferences`, preferences).then(this.resolve).catch(this.reject)
  }

  getFullProfile(id: number) {
    return this.http().get(`${this.baseUrl}/staff-profile/${id}`).then(this.resolve).catch(this.reject)
  }

  archiveOrganizationUser(id: number, data) {
    return this.http().post(`${this.baseUrl}/archive-organization-user/${id}`, data).then(this.resolve).catch(this.reject)
  }
}

export default new User();