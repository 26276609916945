import {
  IRenewSubscriptionInputDto,
  ISubscription,
  ISubscriptionDto,
} from "@/definitions/interfaces"
import BaseModel from "./base"

class Subscription extends BaseModel {
  create(customer) {
    return this.http()
      .post(`${this.baseUrl}/subscription`, customer)
      .then(this.resolve)
      .catch(this.reject)
  }

  get(): Promise<ISubscriptionDto> {
    return this.http()
      .get(`${this.baseUrl}/organization-subscription`)
      .then(this.resolve)
      .catch(this.reject)
  }

  renew(input: IRenewSubscriptionInputDto) {
    return this.http()
      .post(`${this.baseUrl}/organization-subscription/renew`, input)
      .then(this.resolve)
      .catch(this.reject)
  }

  cancel(): Promise<boolean> {
    return this.http()
      .post(`${this.baseUrl}/cancel-subscription`)
      .then(this.resolve)
      .catch(this.reject)
  }
}

export default new Subscription()
