import React, { Component } from 'react';
import { IAppContext, ISubscription } from '../../definitions/interfaces';
import SubscriptionModel from '@models/subscription';
import { Alert, Button } from '@mui/material';
import { AppContext } from '../../context/context';
import { navigate } from 'gatsby';

type Props = {
    context: IAppContext;
};

type State = {
    subscriptionPlan: ISubscription | unknown;
    showBanner: boolean;
    showRenewBanner?: boolean;
    isLoading: boolean;
};

export default class SubscriptionPlanBanner extends Component<Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    constructor(props) {
        super(props);
        this.state = {
            subscriptionPlan: {},
            showBanner: false,
            isLoading: true
        };
    }

    componentDidMount() {
        if(this.context?.user?.role !== 'admin'){
            return;
        }

        this.getSubscriptionPlan();

    }

    getSubscriptionPlan() {
        SubscriptionModel.get().then((subscriptionPlan) => {
            let showBanner = false;
            let showRenewBanner = false;

            if (subscriptionPlan?.stripe_subscription?.status !== 'active') {
                // showRenewBanner = true;
                showBanner = true;
            }

            this.setState({ subscriptionPlan, showBanner, showRenewBanner, isLoading: false });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false, showBanner: true });
        });
    }

    viewAccount() {
        navigate('/admin/subscription/renew');
        this.setState({ showBanner: false });    
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }
        
        if (this.state.showRenewBanner && this.context?.user?.role === 'admin') {
            return (
                <div className='fixed top-0 left-0 w-full z-50 text-center pt-2'>
                    <div className='max-w-md mx-auto'>
                        <Alert severity="warning" className='rounded-none text-center'>
                            <div className='w-full flex items-center'>
                                There is an issue with your subscription.
                                <Button className="text-xs ml-2" variant="contained" color="inherit" size="small" onClick={()=> this.viewAccount()}>View account</Button>
                            </div>
                        </Alert>
                    </div>
                </div>
            );
        }
        return null;
    }
}