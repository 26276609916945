import { DEFAULT_DASHBOARD_SETTINGS, DEFAULT_GENERAL_FEATURE_SETTINGS } from "@/constants";
import { IAppSettings } from "@/definitions/interfaces";
import _ from 'lodash';

export const parseAppSettings = (settingsDto: any): Promise<IAppSettings> => {
  return new Promise((resolve, reject) => {
            let general: any = {};
            let performance_review_settings = {
                reviewOptions: []
            }
            let public_holidays = {
                customHolidays: [],
                countries: [],
            };
            let roster_settings = {};
            // parse general settings
            if (settingsDto.general) {
                try {
                    general = JSON.parse(settingsDto.general);

                    if(general?.feature_roster === undefined){
                        general = { general, ...DEFAULT_GENERAL_FEATURE_SETTINGS };
                    }
                } catch {
                    general = {};
                }
            }
            // parse performance review settings 
            if (settingsDto.performance_review_settings) {
                try {
                    performance_review_settings = JSON.parse(settingsDto.performance_review_settings);

                    if (_.isArray(performance_review_settings?.reviewOptions)) {
                    }
                    else {
                        throw (new Error('Review settings must be a list'));
                    }
                }
                catch {
                    performance_review_settings = {
                        reviewOptions: []
                    };
                }
            }

            // parse roster settings
            if (settingsDto.roster.roster_data) {
                try {
                    roster_settings = JSON.parse(settingsDto.roster.roster_data);
                }
                catch {
                    roster_settings = {
                        businessHours: {
                          start: new Date('2022-07-21T18:00:00.159Z'),
                          end: new Date("2022-07-22T06:30:00.381Z")
                        },
                        isEditableByManagers: false,
                        nestStaffMembersInRooms: true,
                        showAllspaces: true,
                        showCalendarView: false,
                        showDayView: false,
                        showStaffMemberAvatars: true,
                        showStaffMemberNames: true,
                        showStaffMembersPosition: false,
                        showTimeslotRange: true,
                        showWeekView: false,
                        useProfileColors: true,
                        showOnlyBusinessHours: true,
                      }
                  };
            }

            if(settingsDto.public_holidays) {
                
                try {
                    public_holidays = JSON.parse(settingsDto.public_holidays);
                }
                catch {
                    // public_holidays = {}; keep initial value
                }
            }

            if(settingsDto.dashboard) {
                try {
                    settingsDto.dashboard = JSON.parse(settingsDto.dashboard);
                }
                catch {
                    settingsDto.dashboard = DEFAULT_DASHBOARD_SETTINGS;
                }
            }
            else {
                settingsDto.dashboard = DEFAULT_DASHBOARD_SETTINGS;
            }

             resolve({ 
                ...settingsDto,
                general, 
                performance_review_settings, 
                roster_settings, 
                public_holidays,
            })
  });
}